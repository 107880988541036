import { IData } from 'shared/types/dataType'

export const environmentCommitments = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: "Pour l'environnement",
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/commitments/environment-commitments/environment-commitment-mobile.png',
            urlDesktop: '/assets/images/commitments/environment-commitments/environment-commitment-desktop.png'
          }
        },
        standFirst:
          'Depuis plus de 15 ans, le Groupe iliad agit pour limiter l’impact de ses activités sur l’environnement. En 2021, nous avons voulu aller plus loin en formalisant une stratégie climat ambitieuse. Depuis, toutes nos équipes se mobilisent au quotidien pour tenir nos engagements. En février 2024, l’initiative Science Based Target a approuvé nos objectifs de réduction d’émissions carbone.'
      },
      paragraphs: [
        {
          isQuinconce: false,
          title: 'Des efforts significatifs depuis plus de 15 ans',
          content: `
Le Groupe iliad agit depuis longtemps pour réduire significativement l’empreinte environnementale de ses activités. **Nos réseaux Fixe et Mobile** représentent la part la plus importante dans notre consommation énergétique globale. Depuis de nombreuses années, notre Groupe achète des équipements de dernière génération qui se distinguent par leur efficience énergétique. Nous avons aussi fait des choix qui nous permettent d’optimiser l’empreinte carbone de nos **data centers**, en les alimentant depuis 2017 par de l’énergie 100% garantie d’origine renouvelable ou en privilégiant l’implantation de nos data centers en France dans le cadre de notre stratégie de Cloud souverain. Nous avons, en complément, mis en place des **innovations technologiques** remarquables : notre dernier data center, par exemple, intègre un procédé de refroidissement adiabatique qui permet de se passer de climatisation et de gaz frigorifique pour le refroidissement des infrastructures.`
        },
        {
          isQuinconce: true,
          content:
            'Le Groupe lutte par ailleurs depuis toujours **contre l’obsolescence programmée**, en ne mettant sur le marché que des Freebox conçues pour durer. La Freebox Révolution bénéficie ainsi d’une durée de vie supérieure à 10 ans. La Freebox Delta et la Freebox Pop ont été conçues suivant la même logique, en sélectionnant exclusivement des technologies et des matériaux qui durent. Chez nous, toutes les box et les accessoires collectés sont **reconditionnés ou réparés**, tous les déchets recyclés et valorisés. Nous avons aussi fortement réduit le recours aux **emballages à usage unique**, tandis que nos process de préparation des colis vers les abonnés minimisent la consommation de cartons. Enfin, nos équipes de R&D ont pour objectif de concevoir des produits de plus en plus puissants, mais qui consomment de moins en moins d’électricité. La Freebox Pop consomme par exemple **40% en moins** par rapport à la Révolution ou à la mini 4K.',
          image: '/assets/images/commitments/environment-commitments/screen.jpg',
          alt: 'simulation logicielle Freebox Pop',
          caption: 'Simulation logicielle de la Freebox Pop'
        },
        {
          isQuinconce: true,
          content:
            'Afin de réduire les émissions de CO2 générées par nos **flux logistiques,** nous avons opté pour des solutions radicales. Nous avons notamment **renoncé au transport aérien,** sauf en cas d’absolue nécessité. En parallèle, **toute notre chaîne logistique** a été repensée : optimisation des chargements de véhicules, suppression des déplacements à vide, augmentation du taux de remplissage des camions et des conteneurs, utilisation de palettes de transport en plastique réutilisables… Le Groupe possède aussi une flotte de **4500 véhicules** pour ses techniciens qui génèrent des émissions de **CO<sub>2</sub>**. Nous avons donc lancé un ambitieux programme pour les réduire au maximum. La flotte a notamment été renouvelée avec des véhicules qui émettent beaucoup moins de particules fines (NOx).',
          image: '/assets/images/commitments/environment-commitments/ship.jpg',
          alt: 'limtation fret aérien',
          caption: 'Depuis de nombreuses années, nous avons renoncé au fret aérien, sauf en cas d’absolue nécessité. '
        },
        {
          isQuinconce: false,
          title: 'Nos 1O engagements pour le climat',
          content:
            'En 2021, nous avons néanmoins décidé **d’aller encore plus loin.** Sans effort supplémentaire de notre part, nous redoutions un doublement de nos émissions de CO2 à l’horizon 2050, du fait notamment de la croissance continue du trafic de données, de l’augmentation de notre nombre d’abonnés et de notre diversification géographique.<br></br>Le 21 janvier 2021, nous avons publié notre stratégie climat et annoncé **10 engagements que nous avons pris collectivement.** Ils s’articulent autour de 4 axes d’action :<ul><li>**mobiliser nos ressources,** à la fois humaines, techniques et financières : le Groupe s’est notamment engagé à investir 1 milliard d’euros sur 15 ans pour atteindre ses objectifs,</li><li>**transformer tous nos métiers** pour réduire au maximum nos émissions carbone, </li><li>**contribuer à la neutralité carbone mondiale,** en construisant de nouvelles capacités d’énergies renouvelables et en investissant dans des puits de carbone,</li><li>**communiquer régulièrement et en toute transparence** sur les avancées de notre plan climat.</li></ul>'
        },
        {
          isQuinconce: false,
          title: 'Des objectifs de décarbonation approuvés par la SBTi en 2024',
          content:
            'L’initiative Science Based Targets (SBTi) est devenue le **standard** permettant aux entreprises de fixer des objectifs crédibles pour faire face à la crise climatique. En juin 2023, le Groupe iliad a **soumis à examen sa trajectoire** et ses objectifs de décarbonation à la SBTi. <br></br>**En février 2024,** la SBTi a approuvé les objectifs de réduction des émissions carbone du Groupe à court-terme et Net-Zéro Standard. Nous nous engageons : <ul><li>à réduire nos émissions scopes 1 et 2 de **60% en valeur absolue d’ici 2030** par rapport à 2022, et à réduire nos émissions scope 3 de **46% en valeur absolue d’ici 2030** par rapport à 2022,</li><li>à réduire nos émissions scopes 1 et 2 de **90% en valeur absolue d’ici 2050** par rapport à 2022, et à réduire nos émissions scope 3 de **90% en valeur absolue d’ici 2050** par rapport à 2022.</li></ul>'
        }
      ],
      moreInformation: {
        sectionTitle: 'Pour en savoir plus',

        content: [
          {
            linkTitle: `
Voir les avancées de nos engagements pour le climat : [Rapport RSE Groupe 2023](https://www.iliad.fr/media/Rapport_RSE_iliad_2023_5af482c7d0.pdf)
              `,
            image: 'https://s3.fr-par.scw.cloud/iliad-strapi/Thumbnails_1920x1080_RAPPORT_RSE_43d3f54e0e.png',
            alt: 'image livret engagement'
          },
          {
            linkTitle: `
Télécharger l’infographie : [Nos 10 engagements pour le climat](https://www.iliad.fr/media/200524_Iliad_Infographies_climat_milliard_2024_d6c31e5697.pdf)
              `,
            image: 'https://iliad-strapi.s3.fr-par.scw.cloud/RSE_Infographie_CLIMAT_Iliad_1920x1080_8d517e3223.png',
            alt: 'image infographie engagement'
          }
        ]
      }
    },
    en: {
      header: {
        banner: {
          title: 'For the environment',
          image: {
            alt: 'environment',
            urlMobile: '/assets/images/commitments/environment-commitments/environment-commitment-uk-mobile.png',
            urlDesktop: '/assets/images/commitments/environment-commitments/environment-commitment-uk-desktop.png'
          }
        },
        standFirst:
          'The iliad Group has been taking action for more than 15 years now to limit the environmental impact of its operations. In 2021, we decided to take this action to another level by launching an ambitious Climate Strategy, and all of our teams are working hard every day to make sure we keep to the environmental pledges we’ve made. In February 2024, our carbon reduction targets were validated by the Science Based Targets initiative.'
      },
      paragraphs: [
        {
          isQuinconce: false,
          title: 'Working hard for the environment for more than 15 years',
          content:
            "At the iliad Group we've been working hard for a long time to significantly reduce the environmental footprint of our activities. Our **Fixed and Mobile networks** account for the majority of our overall energy consumption, and for many years now we’ve been purchasing latest-generation network equipment that is highly energy efficient. We’ve also made strategic choices that have enabled us to minimize the carbon footprint of our **data centers.** Since 2017, the data centers’ power supply has come from 100% guaranteed renewable sources, and wherever possible we’ve based them in France, in line with our sovereign cloud strategy. In parallel, we’ve deployed **state-of-the-art technological innovations,** such as an adiabatic cooling process at our most recent data center, which means that we don’t need to use air conditioning or refrigerant gases to cool its infrastructure."
        },
        {
          isQuinconce: true,
          content:
            "Since our very beginnings we've always **fought against programmed obsolescence,** with all of our Freeboxes made to last. For example, the Freebox Revolution has a lifespan of more than 10 years. The Freebox Delta and Freebox Pop were designed based on the same principles, by only selecting technologies and materials that will stand the test of time. All of the Freeboxes and their accessories that are returned to us are **refurbished or repaired** whenever possible, and any waste is collected for recycling or re-use. We've also drastically reduced our utilization of **single-use packaging,** and we've cut right down on the cardboard we use when packaging items for delivery to our subscribers. And we’ve set our R&D teams the objective of designing products that are not only more powerful than before, but also more energy efficient. For instance, the Freebox Pop uses **40% less electricity** than the Freebox Revolution or mini 4K.",
          image: '/assets/images/commitments/environment-commitments/screen.jpg',
          alt: 'Software simulation of the Freebox Pop',
          caption: 'Software simulation of the Freebox Pop'
        },
        {
          isQuinconce: true,
          content:
            "In order to reduce the carbon emissions generated by our **supply and delivery chains,** we've opted for radical solutions. In particular, **we no longer use air freight** except when absolutely necessary. In parallel, we’ve totally rethought our **whole supply chain** by optimizing vehicle loads, avoiding empty runs, increasing the fill rate of trucks and containers, and using reusable plastic shipping pallets. We've also launched a large-scale program to reduce the carbon emissions generated by the **fleet of 4,500 vehicles** used by our technicians, replacing our existing fleet with vehicles that emit far fewer fine particles (NOx).",
          image: '/assets/images/commitments/environment-commitments/ship.jpg',
          alt: 'limtation fret aérien',
          caption: 'For many years now, we haven’t used air freight except when absolutely necessary'
        },
        {
          isQuinconce: false,
          title: 'Our 10 climate pledges',
          content:
            "In 2021, we decided to **go even further in our environmental action,** because we realized that due to the ongoing increase in data traffic, our expanding subscriber base and our geographic diversification, our carbon emissions could double by 2050 if we didn’t make an extra effort. <br></br>That's why, on January 21, 2021 we published our Climate Strategy, underpinned by **10 pledges that all of us at the iliad Group signed up to.** These pledges are structured around four key action areas:<ul><li>**Mobilize our human, technical and financial resources,** in particular by investing €1 billion over 15 years to meet our environmental objectives.</li><li>**Transform the way we run all of our businesses** so as to reduce our carbon emissions as much as possible.</li><li>**Contribute to global carbon neutrality,** by helping create more renewable energy capacity and investing in carbon sinks.</li><li>**Communicate regularly and totally transparently** about our climate performance.</li></ul>"
        },
        {
          isQuinconce: false,
          title: 'Carbon reduction targets validated by the SBTi in 2024',
          content:
            'The Science Based Targets Initiative (SBTi) has become the global standard for enabling companies to set credible objectives in order to help tackle the climate crisis. In June 2023, the iliad Group submitted its carbon reduction pathway and targets to the SBTi. <br></br>In February 2024, the SBTi validated the Group’s carbon reduction targets, both its short-term targets and those for achieving the SBTi’s Corporate Net-Zero Standard. In our carbon reduction pathway we’ve committed to: <ul><li>Reduce absolute Scope 1 and Scope 2 GHG emissions by **60% by 2030** from a 2022 base year, and reduce absolute Scope 3 GHG emissions **by 46% by 2030** from a 2022 base year.</li><li>Reduce absolute Scope 1 and Scope 2 GHG emissions by **90% by 2050** from a 2022 base year, and reduce absolute Scope 3 GHG emissions by **90% by 2050** from a 2022 base year.</li></ul>'
        }
      ],
      moreInformation: {
        sectionTitle: 'To find out more :',
        content: [
          {
            linkTitle: `
See the actions we've been taking in relation to our climate pledges: [2023 Group CSR Report](https://www.iliad.fr/media/Rapport_RSE_iliad_2023_Eng_62293a71cf.pdf)
              `,
            image: 'https://s3.fr-par.scw.cloud/iliad-strapi/Thumbnails_1920x1080_RAPPORT_RSE_1_1_805a7d6c86.png',
            alt: 'image livret engagement'
          },
          {
            linkTitle: `
Download our summary statement: [Our 10 climate pledges](https://www.iliad.fr/media/200524_Iliad_Infographies_climat_milliard_2024_en_1b5945470f.pdf)
              `,
            image:
              'https://iliad-strapi.s3.fr-par.scw.cloud/RSE_Summaru_Statement_CLIMAT_Iliad_UK_1920x1080_2d8bc6eb73.png',
            alt: 'image infographie engagement'
          }
        ]
      }
    }
  }[language]
}
