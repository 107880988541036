import { IData } from 'shared/types/dataType'

export const groupStructure = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Stucture du groupe',
          image: {
            alt: 'Stucture du groupe',
            urlMobile: '/assets/images/investors/group-structure/banner-fr-mobile.png',
            urlDesktop: '/assets/images/investors/group-structure/banner-fr-desktop.png'
          }
        },
        standFirst: `Inventeur de la 1<sup>ère</sup> box triple-play au monde, le Groupe Iliad, créé au début des années 90, est aujourd’hui un acteur majeur des télécommunications en Europe qui se distingue par ses offres innovantes, simples et attractives. Maison-mère de Free en France, d’Iliad en Italie et de Play et UPC Polska en Pologne, le Groupe compte plus de 18 200 collaborateurs au service de 49,8
millions d’abonnés actifs et a généré un chiffre d’affaires de 9,5 milliards
d’euros sur les douze derniers mois à fin mars 2024.`
      },
      paragraphs: [
        {
          content: `* En France, le Groupe est un opérateur intégré fixe et mobile très haut débit qui comptait, à fin juin 2024, **22,8 millions d’abonnés particuliers** (15,3 millions d’abonnés mobiles et 7,5 millions d’abonnés fixes);

* En Italie, où il s’est lancé en 2018 sous la marque Iliad, le Groupe est devenu **le 4<sup>ème</sup> opérateur mobile du pays et compte aujourd'hui plus de 11,2 millions d’abonnés mobiles.**
En janvier 2022, iliad italie a lancé son offre Fixe avec une offre 100% Fibre et une box dédiée, baptisée "iliadbox". En mai dernier, iliad Italia a lancé sa dernière révolution, iliadbusiness, l'offre mobile pour les entreprises et les commerces, qui repose sur les valeurs clés d'iliad Italia.

* Avec l’acquisition, en novembre 2020, de l’opérateur mobile polonais Play, le Groupe iliad est devenu **le 6<sup>ème</sup> opérateur mobile en Europe en nombre d’abonnés** (hors M2M).
<br>En  2021, le Groupe a renforcé sa présence en Pologne avec l'annonce de l'acquisition de l'opérateur fixe UPC Polska (transaction finalisée le 1er  avril 2022)

* Fin avril 2024, le Groupe iliad est devenu l'actionnaire de référence du Groupe Tele2.

HoldCo II est une société holding contrôlée par iliad Holding dont le Fondateur détient l’intégralité du capital.
<div style="display:flex;justify-content:center;padding:30px 0 0 0;">
<img src="/assets/images/investors/group-structure/schema-fr.png" style="width:100%;max-width:500px;" />
</div>`
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'Group structure',
          image: {
            alt: 'Group structure',
            urlMobile: '/assets/images/investors/group-structure/banner-en-mobile.png',
            urlDesktop: '/assets/images/investors/group-structure/banner-en-desktop.png'
          }
        },
        standFirst: `Formed in the early 1990s, the iliad Group is the inventor of the world’s first triple-play box and has grown into a major European telecoms player, standing out for its innovative, straightforward and attractive offerings. The Group is the parent of Free in France, iliad in Italy and Play and UPC Polska in Poland, over 18,200 employees serving 49.8 million subscribers, and
generated €9.5 billion in revenues over the last twelve months.`
      },
      paragraphs: [
        {
          content: `* In France, the Group is an integrated Fixed and Mobile Ultra-Fast operator and had **22.8 million retail subscribers** as of June 3O, 2024 (15.3 million
mobile subscribers and 7.5 million fixed subscribers).

* In Italy – where the Group launched its business in 2018 under the iliad brand, **becoming the country’s fourth mobile operator** – it has now more than 11.2 million mobile subscribers.  In January 2022, iliad Italy launched its Fixed offer with a 100% Fiber offer and a dedicated box, called "iliadbox". Last May, iliad Italia launched its latest revolution, iliadbusiness, the mobile offer for enterprises and businesses, embedding all the iliad Italia key values.

* With the acquisition of the Polish mobile operator Play in November 2020, the iliad Group has **become Europe’s sixth-largest mobile operator by number of subscribers** (excluding M2M).
<br>In 2021, the Group strengthened its presence in Poland with the announcement of the acquisition of the fixed-line operator UPC Polska (transaction completed on 1 April 2022).

* In late April, the iliad Group became the main shareholder of the Tele2 Group.

HoldCo II is a holding company controlled by iliad Holding fully owned by his Founder.

<div style="display:flex;justify-content:center;padding:30px 0 0 0;">
<img src="/assets/images/investors/group-structure/schema-en.png" style="width:100%;max-width:500px;" />
</div>
          `
        }
      ]
    }
  }[language]
}
